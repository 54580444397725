import { ContainerXl } from '/features/buildingBlocks/Container'
import { CtaWithOptionalImage } from '/features/buildingBlocks/CtaWithOptionalImage'
import { LocalShadowCasting } from '/features/pageOnly/home/LocalShadowCasting'
import { LocalSectionSand } from '/features/pageOnly/home/LocalSectionSand'

import styles from './ImageWithCtaWrapper.css'

export function ImageWithCtaWrapper({ cta }) {
  return (
    <LocalSectionSand layoutClassName={styles.localSectionSand} dataX='introduction'>
      <LocalShadowCasting layoutClassName={styles.shadowCastingLayout} />
      <div className={styles.introductionContainer}>
        <ContainerXl>
          <CtaWithOptionalImage {...{ cta }} />
        </ContainerXl>
      </div>
    </LocalSectionSand>
  )
}

